/* eslint-disable complexity */
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, MouseEvent as ReactMouseEvent } from 'react';

import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';
import Button from 'components/Button/Button';

import styles from './Gateway.module.scss';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import PatientContentLayout from 'components/PatientContentLayoutV2/PatientContentLayout';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_HELM_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { CLIENT_BRAND, CLIENT_BRAND_LABEL } from 'interfaces/ClientBrand';
import CaWHeader from 'CaW/components/CaWHeader/CaWHeader';
import ButtonCaW from 'CaW/components/ButtonCaW/ButtonCaW';
import classnames from 'classnames';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import GatewayDone from './GatewayDone/GatewayDone';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';

interface GatewayProps {
  state: 'done' | 'saveAndExit' | 'welcomeBack';
  onReturnToAssessment: () => void;
  assessment?: FirstAssessment;
  clinicianAvatar?: string;
  clinicianName?: string;
  isQueryParamToken?: boolean;
  userInfo?: {
    clientRecordId: string;
    initials: string;
    initialsBackgroundColor: string;
    childFirstName?: string;
  };
}

const Gateway = ({
  state,
  onReturnToAssessment,
  assessment,
  clinicianAvatar,
  clinicianName,
  isQueryParamToken,
  userInfo
}: GatewayProps) => {
  const { user } = useAuth0();

  const handleReturnToCheckIn = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onReturnToAssessment();
  };

  const totalQuestions = useMemo(() => {
    const {
      checkInQuestions = [],
      clinicalAssessments = [],
      microJournalQuestions = [],
      onboardingQuestions = {
        assessmentField: [] as FirstAssessment['assessment']['onboardingQuestions']['assessmentField']
      }
    } = assessment?.assessment || {};

    const clinicalAssessmentsLength = clinicalAssessments.length;
    const checkInQuestionsLength = checkInQuestions.flatMap((checkInQuestion) =>
      checkInQuestion.sections.flatMap((section) => section.questions)
    ).length;
    const microJournalQuestionsLength = microJournalQuestions.flatMap((microJournalQuestion) =>
      microJournalQuestion.sections.flatMap((section) => section.questions)
    ).length;
    const onboardingQuestionsLength = onboardingQuestions.assessmentField.flatMap((onboardingQuestion) =>
      onboardingQuestion.sections.flatMap((section) => section.questions)
    ).length;

    return clinicalAssessmentsLength + checkInQuestionsLength + microJournalQuestionsLength + onboardingQuestionsLength;
  }, [assessment]);

  const renderClientProfileAvatar = () => (
    <div className={styles.headerContainer}>
      {IS_HELM_APP ? (
        <HelmHeader
          classNames={styles.helmHeader}
          noWrapper
          whiteFont
          logoWithoutText
          withPadding
          whiteLogo
          withMenu
          homeLink
        />
      ) : IS_SOMEONE_HEALTH_APP ? (
        <SomeoneHealthHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont />
      ) : IS_CAW_APP ? (
        <CaWHeader classNames={styles.shHeader} withPadding withMenu homeLink blueMenu />
      ) : IS_EASE_APP ? (
        <EaseHeader classNames={styles.shHeader} withPadding withMenu homeLink greenMenu />
      ) : IS_RECHARGE_APP ? (
        <RechargeHeader classNames={styles.tacklitHeader} withPadding withMenu homeLink whiteFont whiteMenu />
      ) : IS_SELECT_APP ? (
        <SelectHeader className={styles.tacklitHeader} withPadding withMenu homeLink />
      ) : (
        <div className={styles.tacklitHeader}>
          <ClientHeader withMenu noPadding />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classnames(
        styles.container,
        IS_CAW_APP && 'caw-theme',
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme'
      )}
    >
      <div className={styles.contentWrapper}>
        {state === 'welcomeBack' && (
          <PatientContentLayout className={styles.wrapper}>
            {renderClientProfileAvatar()}
            <div className={styles.content}>
              <span className={styles.title}>Welcome {isQueryParamToken ? userInfo?.initials : user?.name}.</span>
              <p>Request to complete assessment by:</p>
              {(clinicianAvatar || clinicianName) && (
                <div className={styles.clinicianInfo}>
                  {clinicianAvatar && (
                    <img className={styles.clinicianAvatar} src={clinicianAvatar} alt="Clinician avatar" />
                  )}
                  {clinicianName && <span className={styles.clinicianName}>{clinicianName}</span>}
                </div>
              )}
              {userInfo?.childFirstName && <p>For {userInfo.childFirstName}</p>}
              {assessment?.note && <p>{assessment.note}</p>}
            </div>
          </PatientContentLayout>
        )}
        {state === 'saveAndExit' && (
          <PatientContentLayout className={styles.wrapper}>
            {renderClientProfileAvatar()}
            <div className={styles.content}>
              <span className={styles.title}>Great progress.</span>
              <p>You have taken the first steps, well done.</p>
              <p>We have saved your answers thus far, you can return and complete whenever you like.</p>
              <span className={styles.signature}>Team {IS_HELM_APP ? 'HELM' : CLIENT_BRAND_LABEL[CLIENT_BRAND]}</span>
            </div>
          </PatientContentLayout>
        )}
        {state === 'done' && (
          <PatientContentLayout className={styles.wrapper}>
            {renderClientProfileAvatar()}
            <div className={styles.content}>
              <GatewayDone totalQuestion={totalQuestions} clinicianName={clinicianName} />
            </div>
          </PatientContentLayout>
        )}
      </div>
      {(state === 'welcomeBack' || state === 'saveAndExit') && (
        <div className={styles.card}>
          <div className={styles.returnContent}>
            <div className={styles.title}>
              The assessment has <strong>{totalQuestions}</strong> sections and should take less than{' '}
              <strong>{(totalQuestions || 0) * 40}</strong> seconds to complete
            </div>
            <div className={styles.buttonWrapper}>
              {IS_SOMEONE_HEALTH_APP ? (
                <ButtonSH className={styles.button} onClick={handleReturnToCheckIn}>
                  {state === 'welcomeBack' ? "Let's Begin" : state === 'saveAndExit' && 'Return to assessment'}
                </ButtonSH>
              ) : IS_CAW_APP ? (
                <ButtonCaW className={styles.button} onClick={handleReturnToCheckIn}>
                  {state === 'welcomeBack' ? "Let's Begin" : state === 'saveAndExit' && 'Return to assessment'}
                </ButtonCaW>
              ) : IS_EASE_APP ? (
                <ButtonEase className={styles.button} onClick={handleReturnToCheckIn}>
                  {state === 'welcomeBack' ? "Let's Begin" : state === 'saveAndExit' && 'Return to assessment'}
                </ButtonEase>
              ) : IS_RECHARGE_APP ? (
                <ButtonRecharge className={styles.button} onClick={handleReturnToCheckIn}>
                  {state === 'welcomeBack' ? "Let's Begin" : state === 'saveAndExit' && 'Return to assessment'}
                </ButtonRecharge>
              ) : IS_SELECT_APP ? (
                <ButtonSelect className={styles.button} onClick={handleReturnToCheckIn}>
                  {state === 'welcomeBack' ? "Let's Begin" : state === 'saveAndExit' && 'Return to assessment'}
                </ButtonSelect>
              ) : (
                <Button className={styles.button} variant="primary" onClick={handleReturnToCheckIn}>
                  {state === 'welcomeBack' ? "Let's Begin" : state === 'saveAndExit' && 'Return to assessment'}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gateway;
