import { configInterface } from './config';
import AUPrivacy from 'pages/SignUp/PolicyPage/components/AUPrivacy';

export const configAU: configInterface = {
  countryCode: 'au',
  moneyIcon: 'monetization_on',
  currencySymbol: '$',
  currencyCode: 'aud',
  taxLabel: 'TAX',
  taxRateLabel: 'GST',
  codeLabel: 'BSB Code',
  mobileCountryCode: '61',
  defaultTimezone: 'Australia/Melbourne',
  contactMaskNumber: '0482 666 666',
  emergencyNumber: '000',
  lifeLineNumber: '13 11 14',
  practiceSettings: {
    registrationNumberLabel: 'ABN'
  },
  terms: {
    privacy: <AUPrivacy />
  }
};
