import { notification } from 'antd';
import { StatusError } from 'bent';
import { useState, useEffect } from 'react';
import { getSurveyList } from 'utils/http/CheckInService/Surveys/surveys';
import jwt_decode from 'jwt-decode';
import { FacilitatedTokenInterface } from 'interfaces/Assessment/OnboardingAssessment';
import { t } from 'i18next';

interface surveyListInterface {
  _id: string;
  title: string;
  isGPSurvey: boolean;
  totalQuestions: number;
  createdAt: string;
}

export const useFetchSurveyList = (token: string) => {
  const [surveyList, setSurveyList] = useState<surveyListInterface[]>([]);
  const [isSurveyListLoading, setIsSurveyListLoading] = useState(false);

  const fetchSurveyList = async (token: string) => {
    setIsSurveyListLoading(true);
    try {
      const response = await (await getSurveyList(token)).json();
      setSurveyList(response);
      setIsSurveyListLoading(false);
    } catch (ex) {
      const err = ex as StatusError;
      if (err.statusCode && (await err.text()) === 'Token expired') {
        const senderName =
          (jwt_decode(token) as FacilitatedTokenInterface)['https://tacklit.com/senderName'] ??
          t('terminology:practitoner');

        notification.error({
          message: `${t('form.error.expired_token', { senderName })}`
        });
      }
      setIsSurveyListLoading(false);
      console.error(ex);
    }
  };

  useEffect(() => {
    if (token) {
      fetchSurveyList(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { surveyList, isSurveyListLoading };
};
