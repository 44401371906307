import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import styles from './NoAuthLayout.module.scss';
import HelmHeader from '../../helm/components/HelmHeader/HelmHeader/HelmHeader';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_HELM_APP,
  IS_RECHARGE_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import CaWHeader from '../../CaW/components/CaWHeader/CaWHeader';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import classNames from 'classnames';

interface NoAuthLayoutProps {
  children?: any;
}

const NoAuthLayout = ({ children }: NoAuthLayoutProps) => {
  return (
    <div className={classNames(styles.container, IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme')}>
      {IS_HELM_APP ? (
        <HelmHeader noLogin logoWithoutText />
      ) : IS_SOMEONE_HEALTH_APP ? (
        <SomeoneHealthHeader noLogin withPadding pinkLogo />
      ) : IS_CAW_APP ? (
        <CaWHeader noLogin withPadding />
      ) : IS_EASE_APP ? (
        <EaseHeader noLogin withPadding />
      ) : IS_RECHARGE_APP ? (
        <RechargeHeader noLogin withPadding lightLogo />
      ) : (
        <Header />
      )}
      {children}
      <Footer />
    </div>
  );
};

export default NoAuthLayout;
