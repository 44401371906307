import { configInterface } from './config';
import UKPrivacy from 'pages/SignUp/PolicyPage/components/UKPrivacy';

export const configSA: configInterface = {
  countryCode: 'sa',
  moneyIcon: 'monetization_on',
  currencySymbol: 'ر.س',
  currencyCode: 'sar',
  taxLabel: 'TAX',
  taxRateLabel: 'VAT',
  codeLabel: '',
  mobileCountryCode: '966',
  defaultTimezone: 'Asia/Riyadh',
  contactMaskNumber: '050 123 4567',
  emergencyNumber: '997',
  lifeLineNumber: '920029855',
  practiceSettings: {
    registrationNumberLabel: 'CR Number'
  },
  terms: {
    privacy: <UKPrivacy />
  }
};
