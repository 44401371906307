import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import WelcomeMessage from 'pages/PatientPage/HomePage/components/WelcomeMessage/WelcomeMessage';
import { useFetchCompletedCheckInStatus } from 'pages/PatientPage/HomePage/hooks/getCheckInStatus';
import { useFetchPsychometricList } from 'pages/Psychometric/PsychometricList/hooks/getPsychometricList';
import { useGetAllSurveysQuery } from 'redux/endpoints/checkInServices/survey';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './HomePage.module.scss';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import EaseHelmetWrapper from 'Ease/components/EaseHelmetWrapper/EaseHelmetWrapper';
import TaskBox from './components/TaskBox/TaskBox';
import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';

const REFETCH_DATA_INTERVAL_TIME = 15 * 60 * 1000;

const HomePage = () => {
  const { user } = useAuth0();
  const { CONSENT, ONBOARDING, PSYCHOMETRIC, SURVEY } = useEaseRoutesGenerator();
  const { token } = useGetAccessToken();
  const navigate = useNavigate();

  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId, pollingInterval: REFETCH_DATA_INTERVAL_TIME }
  );
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery();
  const clinicianId = attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';
  const { completedCheckInStatus, isCompletedCheckInStatusLoading } = useFetchCompletedCheckInStatus(
    token,
    clinicianId
  );

  const clinicianName = attachedClinicianDetail?.clinician?.name || attachedClinicianDetail?.practice?.name || '';

  const isContentLoading =
    isAttachedClinicianDetailLoading || isClientTasksLoading || isPsychometricListLoading || isSurveyListLoading;

  const surveys = surveyList?.filter(({ isGPSurvey }) => !isGPSurvey) || [];

  const taskCount =
    (clientTasks ? clientTasks.consentList.length + clientTasks.assessmentList.length : 0) +
    surveys.length +
    psychometricList.length;

  return (
    <EaseHelmetWrapper
      title={`EASE Wellbeing | Therapy & Counselling Experts in London - Welcome ${user ? user.name : ''}`}
    >
      <EaseContentLayout className={`${styles.container} ease-theme`}>
        {!isAttachedClinicianDetailLoading && attachedClinicianDetail?.clinician && completedCheckInStatus && (
          <Button
            className={styles.fixedLeftBtn}
            type={'primary'}
            disabled={isAttachedClinicianDetailLoading}
            onClick={() => {
              navigate(`/${attachedClinicianDetail.clinician?.slugUrl}/checkIn`);
            }}
          >
            <div className={styles.newJournalBtn}>
              <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
              NEW JOURNAL ENTRY
            </div>
          </Button>
        )}
        <div className={styles.contentWrapper}>
          <EaseHeader withPadding withMenu homeLink greenMenu />
          <div className={styles.content}>
            {isContentLoading || isCompletedCheckInStatusLoading ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <div className={styles.contentBox}>
                {attachedClinicianDetail && (
                  <WelcomeMessage
                    clinicianName={clinicianName}
                    clinicianDetail={attachedClinicianDetail}
                    completedCheckInStatus={completedCheckInStatus}
                    className={styles.welcomeMessage}
                  />
                )}
                {taskCount > 0 ? (
                  <div className={styles.taskListContainer}>
                    <div className={styles.taskListHeader}>
                      <div className={styles.taskListTitle}>Your pre-session activities</div>
                      <div className={styles.taskListDesc}>
                        {clinicianName} has asked you to complete the following{' '}
                        <strong>{taskCount === 1 ? 'action' : `${taskCount} actions`}.</strong>
                      </div>
                    </div>
                    <div className={styles.taskListBox}>
                      {clientTasks && (
                        <>
                          {clientTasks.consentList.length > 0 && (
                            <TaskBox
                              title={'eSign consent form'}
                              desc={`${clientTasks.consentList.length} actions`}
                              onClick={() => navigate(CONSENT.BASE)}
                            />
                          )}
                          {clientTasks.assessmentList.length > 0 && (
                            <TaskBox
                              title={'Onboarding assessment'}
                              desc={`${clientTasks.assessmentList.length} actions`}
                              onClick={() => navigate(ONBOARDING.BASE)}
                            />
                          )}
                        </>
                      )}
                      {surveyList && !!surveyList.length && (
                        <TaskBox
                          title={'Surveys'}
                          desc={`${surveyList.length} actions`}
                          onClick={() => navigate(SURVEY.BASE)}
                        />
                      )}
                      {psychometricList.length > 0 && (
                        <TaskBox
                          title={'Psychometrics'}
                          desc={`${psychometricList.length} actions`}
                          onClick={() => navigate(PSYCHOMETRIC.BASE)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.desc}>You have no outstanding tasks at this time.</div>
                )}
              </div>
            )}
          </div>
        </div>
      </EaseContentLayout>
    </EaseHelmetWrapper>
  );
};

export default HomePage;
