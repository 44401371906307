export const useSomeoneHealthRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    PRACTITIONER: {
      HOME: `${initPath}/find-psychologist`,
      LISTING: `${initPath}/psychologists`,
      LISTING_WITH_FILTER: `${initPath}/psychologists/:filter`,
      DETAILS: `${initPath}/psychologists-profile/:clinicianId`,
      DIRECTORY: `${initPath}/psychologist-directory`,
      DIRECTORY_WITH_FILTER: `${initPath}/psychologist-directory/:filter`
    },
    SIGNUP: {
      BASE: `${initPath}/signup`,
      MEDICARE: `${initPath}/medicare`,
      CONFIRM_BOOKING: `${initPath}/booking-confirm`,
      WELCOME_BOOKING: `${initPath}/welcome`
    },
    SIGNUP_INVITE: {
      INVITE: `${initPath}/sign-up-with-invite`,
      ADD_PAYMENT: `${initPath}/add-payment`,
      WELCOME_INVITE: `${initPath}/thank-you`
    },
    SIGNUP_DECOUPLE_GP: {
      BASE: `${initPath}/signup-no-gp`,
      MEDICARE: `${initPath}/signup-no-gp/medicare`,
      ADD_PAYMENT: `${initPath}/signup-no-gp/add-payment`,
      WELCOME: `${initPath}/signup-no-gp/welcome`
    },
    INVOICE: {
      VIEW: `${initPath}/invoice/:invoiceId`
    },
    REPORT: {
      VIEW: `${initPath}/report/:reportId`
    },
    LETTER: {
      VIEW: `${initPath}/accounts/:accountId/letters/:letterId`
    },
    AVATAR: `${initPath}/avatar`,
    UPDATE_PROFILE: `${initPath}/profile`,
    MY_PSYCHOLOGIST: `${initPath}/my-psychologist`,
    WAITLIST: `${initPath}/waitlist`,
    HOME: '/',
    PENDING_ACTIONS: `${initPath}/pending-actions`,
    LOGIN: {
      APPOINTMENT_OVERVIEW: `${initPath}/appointment/overview`
    },
    CONSENT: {
      BASE: `${initPath}/consent`,
      DETAILS: `${initPath}/consent/:consentFormId`
    },
    SURVEY: {
      BASE: `${initPath}/surveys`,
      DETAILS: `${initPath}/surveys/:surveyId`
    },
    ONBOARDING: {
      BASE: `${initPath}/onboarding`
    },
    PSYCHOMETRIC: {
      BASE: `${initPath}/psychometrics`,
      DETAILS: `${initPath}/:clinicianSlugUrlOrId/assessment/:assessmentId`
    },
    REFERRAL: `${initPath}/referral`,
    CHECKIN: `${initPath}/:clinicianSlugUrlOrId/checkin`,
    MICRO_JOURNAL_INSIGHTS: `${initPath}/journal-insights`,
    GP: {
      BASE: `${initPath}/gp-booking`,
      CONFIRM_BOOKING: `${initPath}/gp-booking/confirm`,
      COMPLETE: `${initPath}/gp-booking/complete`,
      ADD_PAYMENT: `${initPath}/gp-booking/add-payment`
    }
  };
};
