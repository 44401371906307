import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation, generatePath } from 'react-router-dom';

import { useGetAccessToken } from 'utils/hooks/token';

import { Button } from 'antd';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';

import styles from './HelmHomePage.module.scss';
import TaskBox from 'components/TaskBox/TaskBox';
import { useTranslation } from 'react-i18next';
import { useGetAllSurveysQuery } from 'redux/endpoints/checkInServices/survey';

import backgroundImg from 'helm/assets/images/background/HelmClientBg.jpg';
import WelcomeMessage from 'pages/PatientPage/HomePage/components/WelcomeMessage/WelcomeMessage';
import { useFetchCompletedCheckInStatus } from 'pages/PatientPage/HomePage/hooks/getCheckInStatus';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import HelmHeader from '../../components/HelmHeader/HelmHeader/HelmHeader';
import HelmHelmetWrapper from '../../components/HelmHelmetWrapper/HelmHelmetWrapper';
import queryString from 'query-string';
import { useReserveAppointmentData } from '../../utils/hooks/localData';
import { useFetchPsychometricList } from 'pages/Psychometric/PsychometricList/hooks/getPsychometricList';
import HomeNoTaskBox, { noTaskBoxContentInterface } from '../../../components/HomeNoTaskBox/HomeNoTaskBox';
import calendarImg from '../../../SomeoneHealth/assets/images/tasks/calendar.png';
import appointmentImg from '../../../SomeoneHealth/assets/images/tasks/appointment.png';
import profileImg from '../../../SomeoneHealth/assets/images/tasks/profile.png';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';

const REFETCH_DATA_INTERVAL_TIME = 15 * 60 * 1000;

const HelmHomePage = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const location = useLocation();
  const { from }: { from?: string } = queryString.parse(location.search);
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId, pollingInterval: REFETCH_DATA_INTERVAL_TIME }
  );
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery();
  const clinicianId = attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';
  const { completedCheckInStatus, isCompletedCheckInStatusLoading } = useFetchCompletedCheckInStatus(
    token,
    clinicianId
  );
  const { reserveId } = useReserveAppointmentData();
  const { CONSENT, SURVEY, ONBOARDING, CHECKOUT, PSYCHOMETRIC, LOGIN, UPDATE_PROFILE, COUNSELLORS_CALENDAR } =
    useHelmRoutesGenerator();

  if (from === 'signup' && reserveId) {
    navigate(CHECKOUT);
  }

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';
  const isContentLoading =
    isAttachedClinicianDetailLoading || isClientTasksLoading || isPsychometricListLoading || isSurveyListLoading;

  const noTaskBoxContent: noTaskBoxContentInterface[] = [
    {
      title: 'Book a new appointment',
      desc: 'View real-time availability and quickly and easily lock in a time that is convenient for you.',
      image: calendarImg,
      button: {
        label: 'Edit my profile',
        link: generatePath(COUNSELLORS_CALENDAR.DETAILS, {
          clinicianId: attachedClinicianDetail?.clinician?._id || ''
        })
      }
    },
    {
      title: 'View appointments & invoices',
      desc: 'Find all detail on past and upcoming appointments, including your telehealth link.',
      image: appointmentImg,
      button: {
        label: 'View my appointment',
        link: LOGIN.APPOINTMENT_OVERVIEW
      }
    },
    {
      title: 'Manage your profile',
      desc: 'Complete and update your information to save you time, all stored securely with AES256 encryption',
      image: profileImg,
      button: {
        label: 'Edit my profile',
        link: UPDATE_PROFILE
      }
    }
  ];

  const hasTask =
    (clientTasks && (clientTasks.consentList.length > 0 || clientTasks.assessmentList.length > 0)) ||
    (surveyList && surveyList.length) ||
    psychometricList.length > 0;

  return (
    <HelmHelmetWrapper title={`HELM - Welcome ${user ? user.name : ''}`}>
      <PatientContentLayout
        className={styles.container}
        style={{
          backgroundImage: `url('${backgroundImg}')`,
          backgroundColor: 'transparent !important',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        {!isAttachedClinicianDetailLoading && attachedClinicianDetail?.clinician && completedCheckInStatus && (
          <Button
            className={styles.fixedLeftBtn}
            type={'primary'}
            disabled={isAttachedClinicianDetailLoading}
            onClick={() => {
              navigate(`/${attachedClinicianDetail.clinician?.slugUrl}/checkIn`);
            }}
          >
            <div className={styles.newJournalBtn}>
              <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
              NEW JOURNAL ENTRY
            </div>
          </Button>
        )}
        <div className={styles.contentWrapper}>
          <HelmHeader noWrapper whiteFont whiteLogo logoWithoutText withPadding withMenu homeLink />
          <div className={styles.content}>
            {isContentLoading || isCompletedCheckInStatusLoading ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <div className={styles.contentBox}>
                {attachedClinicianDetail && (
                  <WelcomeMessage
                    clinicianName={clinicianName}
                    clinicianDetail={attachedClinicianDetail}
                    completedCheckInStatus={completedCheckInStatus}
                  />
                )}
                {hasTask ? (
                  <div className={styles.taskListContainer}>
                    <div className={styles.taskListTitle}>
                      <strong>{clinicianName}</strong> has asked you to complete
                    </div>
                    <div className={styles.taskListBox}>
                      {clientTasks && (
                        <>
                          {clientTasks.consentList.length > 0 && (
                            <TaskBox
                              title={t('label.esign_client_consent_form')}
                              desc={`${clientTasks.consentList.length} actions`}
                              onClick={() => navigate(CONSENT.BASE)}
                            />
                          )}
                          {clientTasks.assessmentList.length > 0 && (
                            <TaskBox
                              title={'Onboarding assessment'}
                              desc={`${clientTasks.assessmentList.length} actions`}
                              onClick={() => navigate(ONBOARDING.BASE)}
                            />
                          )}
                        </>
                      )}
                      {surveyList && !!surveyList.length && (
                        <TaskBox
                          title={'Surveys'}
                          desc={`${surveyList.length} actions`}
                          onClick={() => navigate(SURVEY.BASE)}
                        />
                      )}
                      {psychometricList.length > 0 && (
                        <TaskBox
                          title={'Psychometrics'}
                          desc={`${psychometricList.length} actions`}
                          onClick={() => navigate(PSYCHOMETRIC.BASE)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <HomeNoTaskBox taskContent={noTaskBoxContent} />
                )}
              </div>
            )}
          </div>
        </div>
      </PatientContentLayout>
    </HelmHelmetWrapper>
  );
};

export default HelmHomePage;
