import { InputHTMLAttributes, useState } from 'react';
import classnames from 'classnames';

import styles from './Input.module.scss';
import Button from 'components/v2/Button/Button';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClass?: string;
  label?: string;
  labelClass?: string;
  hasError?: boolean;
  noSpacing?: boolean;
  allowShowPassword?: boolean;
  showPasswordButtonClass?: string;
}

const Input = ({
  className,
  inputClass,
  label,
  labelClass,
  placeholder,
  hasError,
  noSpacing,
  allowShowPassword,
  showPasswordButtonClass,
  ...props
}: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const containerClasses = classnames(styles.container, className);
  const inputClasses = classnames(styles.input, hasError && styles.error, noSpacing && styles.noSpacing, inputClass);
  const labelClasses = classnames(
    placeholder ? styles.staticLabel : styles.label,
    hasError && styles.error,
    labelClass
  );
  const showPasswordButtonClasses = classnames(styles.showPasswordButton, showPasswordButtonClass);

  return (
    <div className={containerClasses}>
      <input
        className={inputClasses}
        placeholder={placeholder}
        {...props}
        {...(allowShowPassword && props.type === 'password' && isPasswordVisible ? { type: '' } : { type: props.type })}
      />
      {label && <div className={labelClasses}>{label}</div>}
      {allowShowPassword && props.type === 'password' && (
        <Button
          className={showPasswordButtonClasses}
          variant="secondary"
          icon={isPasswordVisible ? 'visibility' : 'visibility_off'}
          type="button"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        />
      )}
    </div>
  );
};

export default Input;
