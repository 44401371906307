import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, generatePath } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/token';
import { Button } from 'antd';
import styles from './CaWHomePage.module.scss';
import TaskBox from 'components/TaskBox/TaskBox';
import { useTranslation } from 'react-i18next';
import { useGetAllSurveysQuery } from 'redux/endpoints/checkInServices/survey';
import WelcomeMessage from 'pages/PatientPage/HomePage/components/WelcomeMessage/WelcomeMessage';
import { useFetchCompletedCheckInStatus } from 'pages/PatientPage/HomePage/hooks/getCheckInStatus';
import { useFetchPsychometricList } from 'pages/Psychometric/PsychometricList/hooks/getPsychometricList';
import HomeNoTaskBox, { noTaskBoxContentInterface } from '../../../components/HomeNoTaskBox/HomeNoTaskBox';
import appointmentImg from 'CaW/assets/images/tasks/appointment.png';
import calendarImg from 'CaW/assets/images/tasks/calendar.png';
import profileImg from 'CaW/assets/images/tasks/profile.png';
import CaWHelmetWrapper from '../../components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWHeader from '../../components/CaWHeader/CaWHeader';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { CLIENT_BRAND, CLIENT_BRAND_LABEL } from 'interfaces/ClientBrand';
import CaWContentLayout from 'CaW/components/CaWContentLayout/CaWContentLayout';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';

const REFETCH_DATA_INTERVAL_TIME = 15 * 60 * 1000;

const CaWHomePage = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId, pollingInterval: REFETCH_DATA_INTERVAL_TIME }
  );
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery();
  const clinicianId = attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';
  const { completedCheckInStatus, isCompletedCheckInStatusLoading } = useFetchCompletedCheckInStatus(
    token,
    clinicianId
  );
  const { CONSENT, SURVEY, ONBOARDING, PSYCHOMETRIC, LOGIN, PRACTITIONER } = useCaWRoutesGenerator();

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';
  const isContentLoading =
    isAttachedClinicianDetailLoading || isClientTasksLoading || isPsychometricListLoading || isSurveyListLoading;

  const noTaskBoxContent: noTaskBoxContentInterface[] = [
    {
      title: 'Book a new appointment',
      desc: 'View real-time availability and quickly and easily lock in a time that is convenient for you.',
      image: calendarImg,
      button: {
        label: 'Edit my profile',
        link: generatePath(PRACTITIONER.DETAILS, {
          clinicianId: attachedClinicianDetail?.clinician?._id || ''
        }),
        hideButton: true
      }
    },
    {
      title: 'View appointments',
      desc: 'Find all detail on past and upcoming appointments, including your telehealth link.',
      image: appointmentImg,
      button: {
        label: 'View my appointment',
        link: LOGIN.APPOINTMENT_OVERVIEW,
        hideButton: true
      }
    },
    {
      title: 'Manage your profile',
      desc: 'Complete and update your information to save you time, all stored securely with AES256 encryption',
      image: profileImg,
      button: {
        label: 'Edit my profile',
        link: LOGIN.UPDATE_PROFILE
      }
    }
  ];

  const countOfActions =
    (clientTasks ? Object.values(clientTasks).filter((arr: any[]) => arr?.length > 0).length : 0) +
    (surveyList?.length ? 1 : 0) +
    (psychometricList?.length ? 1 : 0);

  const hasTask =
    (clientTasks && (clientTasks.consentList.length > 0 || clientTasks.assessmentList.length > 0)) ||
    (surveyList && surveyList.length) ||
    psychometricList.length > 0;

  return (
    <CaWHelmetWrapper title={`Caraniche at Work - Welcome ${user ? user.name : ''}`}>
      <div className={styles.container}>
        <CaWContentLayout>
          {!isAttachedClinicianDetailLoading && attachedClinicianDetail?.clinician && completedCheckInStatus && (
            <Button
              className={styles.fixedLeftBtn}
              type={'primary'}
              disabled={isAttachedClinicianDetailLoading}
              onClick={() => {
                navigate(`/${attachedClinicianDetail.clinician?.slugUrl}/checkIn`);
              }}
            >
              <div className={styles.newJournalBtn}>
                <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                NEW JOURNAL ENTRY
              </div>
            </Button>
          )}
          <div className={styles.contentWrapper}>
            <CaWHeader withPadding withMenu homeLink blueMenu />
            <div className={styles.content}>
              {isContentLoading || isCompletedCheckInStatusLoading ? (
                <div className={styles.loading}>
                  <LoadingDot />
                </div>
              ) : (
                <div className={styles.contentBox}>
                  {attachedClinicianDetail && (
                    <WelcomeMessage
                      clinicianName={clinicianName}
                      clinicianDetail={attachedClinicianDetail}
                      completedCheckInStatus={completedCheckInStatus}
                      className={styles.welcomeMessage}
                    />
                  )}
                  {hasTask ? (
                    <div className={styles.taskListContainer}>
                      <div className={styles.taskListTitle}>
                        <div className={styles.title}>Your pre session activities</div>
                        <strong>{clinicianName}</strong> has asked you to complete{' '}
                        <strong>{countOfActions} actions.</strong>
                      </div>
                      <div className={styles.taskListBox}>
                        {clientTasks && (
                          <>
                            {clientTasks.consentList.length > 0 && (
                              <TaskBox
                                title={t('label.esign_client_consent_form')}
                                desc={`${clientTasks.consentList.length} actions`}
                                onClick={() => navigate(CONSENT.BASE)}
                                containerClassName={styles.taskBoxClassName}
                              />
                            )}
                            {clientTasks.assessmentList.length > 0 && (
                              <TaskBox
                                title={'Onboarding assessment'}
                                desc={`${clientTasks.assessmentList.length} actions`}
                                onClick={() => navigate(ONBOARDING.BASE)}
                                containerClassName={styles.taskBoxClassName}
                              />
                            )}
                          </>
                        )}
                        {surveyList && !!surveyList.length && (
                          <TaskBox
                            title={'Surveys'}
                            desc={`${surveyList.length} actions`}
                            onClick={() => navigate(SURVEY.BASE)}
                            containerClassName={styles.taskBoxClassName}
                          />
                        )}
                        {psychometricList.length > 0 && (
                          <TaskBox
                            title={'Psychometrics'}
                            desc={`${psychometricList.length} actions`}
                            onClick={() => navigate(PSYCHOMETRIC.BASE)}
                            containerClassName={styles.taskBoxClassName}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <HomeNoTaskBox
                      taskContent={noTaskBoxContent}
                      noTaskMessage={
                        <div className={styles.noTaskMassage}>
                          <div className={styles.label}>
                            Here in your <b>{CLIENT_BRAND_LABEL[CLIENT_BRAND]} account </b> you can manage all aspects
                            of your care program.
                          </div>
                          <div className={styles.taskLabel}>
                            You currently have <span className={styles.number}>0</span> outstanding tasks
                          </div>
                          <div className={styles.label}>
                            If you have any questions, you can reach out to our Client Services Team on{' '}
                            <b>1800 099 444.</b>
                          </div>
                        </div>
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </CaWContentLayout>
      </div>
    </CaWHelmetWrapper>
  );
};

export default CaWHomePage;
